export const currencies = () => {
  return [
      { id: 'KZT', title: "KZT" },
      { id: 'USD', title: "USD" },
      { id: 'EUR', title: "EUR" },
      { id: 'RUB', title: "RUB" },
  ]
}

export default { currencies };
